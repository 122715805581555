<script lang="ts" setup>
import { cn } from '@gem/common';
import { GButton } from '@gem/uikit-v2';
import type { ProductSelectedProps } from '../type';

defineProps<ProductSelectedProps>();

const emit = defineEmits<{
  (e: 'onSyncProduct'): void;
}>();

const DYNAMIC_PRODUCT_STATUS = 'dynamic';

const handleSync = () => {
  emit('onSyncProduct');
};
</script>
<template>
  <div
    class="bg-dark-400 group relative flex h-[56px] cursor-pointer items-center gap-[11px] overflow-hidden rounded-xl p-8"
    :class="{
      'hover:bg-dark-250': !isSalePage,
      '!bg-dark-250': isLoading,
      'border-primary-300 border': productListVisibility,
    }">
    <img
      v-if="convertProductDisplay?.image"
      class="rounded-medium aspect-square h-40 w-40 shrink-0 object-cover"
      :src="convertProductDisplay?.image"
      alt="product feature img" />
    <div v-else class="rounded-medium aspect-square h-40 w-40 shrink-0 overflow-hidden">
      <img src="../../images/no-product.png" alt="no image transparent" />
    </div>
    <p class="text-light-450 font-regular text-12 line-clamp-2 self-center leading-[14px]">
      {{ convertProductDisplay?.title }}
    </p>
    <div
      class="z-5 absolute right-0 hidden h-full items-center justify-end gap-8 pl-[24px] pr-8 group-hover:flex"
      :class="cn({ '!flex': isLoading })"
      :style="{
        background: 'linear-gradient(270deg, #333333 66.5%, rgba(51, 51, 51, 0.8) 85.5%, rgba(51, 51, 51, 0) 100%)',
      }">
      <GButton :loading="isLoading" type="ghost" size="small" only-icon="polaris-refresh" @click.stop="handleSync" />
      <GButton type="ghost" size="small" only-icon="polaris-edit" />
    </div>
  </div>
</template>
