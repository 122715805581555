import { computed, ref } from 'vue';
import type { ProductListPickerProps, ProductListPickerTypesEmit } from '../type';
import { useSetValueModal } from './useSetValueSelected';
export const useHandleDataMultiProductModal = (emit: ProductListPickerTypesEmit, props: ProductListPickerProps) => {
  const multiProductPickerReference = ref();
  const searchProductListReference = ref();

  const optionList = computed(() => {
    const list = [
      {
        id: 'product',
        label: 'Products',
        value: 'PickProduct',
      },
      {
        id: 'collection',
        label: 'Collection',
        value: 'Collection',
      },
    ];
    if (props.pageType === 'GP_PRODUCT') {
      list.push({
        id: 'related',
        label: 'Related',
        value: 'RelatedProduct',
      });
    }
    return list;
  });

  const searchKeyword = computed(() => searchProductListReference.value?.searchKeyword || '');
  const close = () => {
    emit('close');
    searchProductListReference.value.closeSearchSetting();
  };

  const isShowBtnSave = computed(() => props.productSrcType === 'PickProduct' && !props.isNoProductInStore);

  const isShowSearch = computed(
    () =>
      props.productSrcType !== 'RelatedProduct' &&
      (props.productSrcType === 'PickProduct' ? !props.isNoProductInStore : !props.isNoCollectionInStore),
  );

  const isDisabledSave = computed(() => multiProductPickerReference?.value?.isDisabledSave);

  const { handleSelectTypePicker, setValueSelectedProducts, setValueSelectedCollection } = useSetValueModal(
    emit,
    searchProductListReference,
  );

  const handleSave = () => {
    multiProductPickerReference?.value?.save();
    close();
  };
  return {
    close,
    searchKeyword,
    searchProductListReference,
    multiProductPickerReference,
    handleSave,
    isDisabledSave,
    handleSelectTypePicker,
    setValueSelectedProducts,
    setValueSelectedCollection,
    isShowBtnSave,
    isShowSearch,
    optionList,
  };
};
