<script setup lang="ts">
import { Select } from '@gem/control';
import FieldLabel from '@gem/control/src/control/FieldLabel.vue';
import Textarea from '@gem/control/src/control/Textarea.vue';
import { sourcesRelatedTag } from '../constant';
import { useHandleDataRelatedPicker } from '../hooks/useHandleDataRelatedPicker';
import type { RelatedPickerEmit, RelatedPickerProps } from '../type';
import NoticeMessage from './NoticeMessage.vue';

const props = defineProps<RelatedPickerProps>();
const emit = defineEmits<RelatedPickerEmit>();
const { isShowNotice, relatedExclude, handleChange, onCloseNotice, relatedTag, contentRelated, relatedType } =
  useHandleDataRelatedPicker(emit, props);
</script>
<template>
  <div class="flex flex-col gap-16 pt-4">
    <div class="flex min-h-[36px] w-full items-center justify-between gap-16 px-16">
      <div class="flex h-36 items-center gap-4">
        <FieldLabel label="Condition" />
        <g-tooltip
          placement="top"
          content-class="text-text-dark-300"
          :content-styles="{
            'z-index': 301,
          }">
          <g-base-icon
            class="text-text-dark-300"
            name="question"
            width="14"
            height="14"
            viewBox="0 0 16 16"></g-base-icon>
          <template #content>{{ contentRelated.tooltip }}</template>
        </g-tooltip>
      </div>
      <div class="max-w-input-horizontal select-related w-full">
        <Select
          id="relatedTag"
          :value="relatedTag ? relatedTag[0] : 'collection'"
          :options="sourcesRelatedTag"
          dropdown-classes="w-full"
          @control-change="handleChange" />
      </div>
    </div>
    <div
      v-if="relatedTag && (relatedTag[0] === 'collection' || relatedTag[0] === 'tags')"
      class="flex min-h-[36px] w-full items-start justify-between gap-16 px-16">
      <div class="flex h-36 items-center gap-4">
        <FieldLabel label="Exclude" />
      </div>
      <div class="w-[140px]">
        <Textarea
          id="relatedExclude"
          :value="relatedExclude"
          :min-height="86"
          :max-height="86"
          :placeholder="contentRelated.placeholder"
          @control-change="handleChange">
        </Textarea>
      </div>
    </div>
    <NoticeMessage
      v-if="relatedTag && (relatedTag[0] === 'collection' || relatedTag[0] === 'tags')"
      :is-show-notice="isShowNotice"
      :related-type="relatedType"
      @close-notice="onCloseNotice" />
  </div>
</template>
