<script lang="ts" setup>
import { ref, computed } from 'vue';

import { GButton, GPopover } from '@gem/uikit-v2';
import { OptionValue, SettingTitle } from '@gem/element-setting-ui';

import { DYNAMIC_PRODUCT_STATUS, STATIC_PRODUCT_STATUS } from './constant';
import SingleProductPicker from './components/SingleProductPicker.vue';
import AssignedProductList from './components/AssignedProductList.vue';
import SearchProductComponent from './components/SearchProductComponent.vue';
import type { EmitTypeProductPicker, PropsProductPicker } from './types';
import { ThemePageType } from '../../../type/common';

const props = defineProps<PropsProductPicker>();
const emit = defineEmits<EmitTypeProductPicker>();

const reference = ref<Element>();
const popoverReference = ref();
const searchReference = ref();
const searchKeyword = computed(() => searchReference.value?.searchKeyword || '');

const isShowBtnManageAssigned = computed(
  () =>
    props.pageType === ThemePageType.GP_PRODUCT &&
    props.productStatusByPageType === DYNAMIC_PRODUCT_STATUS &&
    !props.isNoAssignedProduct,
);

const isShowSearch = computed(() =>
  props.productStatusByPageType === DYNAMIC_PRODUCT_STATUS ? !props.isNoAssignedProduct : !props.isNoProductInStore,
);

const setValueSelected = (id?: string) => {
  if (id) {
    emit('setValueSelected', id);
  }
};

const handleOpenPopover = () => {
  emit('openModal');
};

const handleClosePopover = () => {
  if (!props.open) return;
  emit('closeModal');
  searchReference.value?.closeSearchSetting();
};

const handleSelectProductMode = (value: 'dynamic' | 'static') => {
  emit('select-product-mode', value);
  searchReference.value?.closeSearchSetting();
};

const handleOpenAssignModal = () => {
  emit('openAssignModal');
  emit('closeModal');
};

const onSearch = (value?: string) => {
  emit('onSearch', value);
};

const onToggle = () => {
  if (!props.open) {
    handleOpenPopover();
    return;
  }
  handleClosePopover();
};
</script>

<template>
  <div ref="reference">
    <GPopover
      ref="popoverReference"
      cls="bg-dark-500"
      button-class="!w-full"
      wrap-content-class="!w-full"
      placement="right-start"
      :offset-top="-8"
      :no-padding="true"
      :placement-offset="20"
      :enable-flip="false"
      :detect-side-bar="true"
      :force-open="open"
      :custom-toggle="true"
      @click="onToggle"
      @open="handleOpenPopover"
      @close="handleClosePopover"
      @click-out-side="handleClosePopover">
      <slot name="btn-popover"></slot>
      <template #content="{ close }">
        <div class="max-h-[516px] w-[280px]">
          <div class="border-dark-300 flex h-[52px] items-center justify-between border-b pl-8 pr-10">
            <div class="flex h-full items-center gap-8 px-8">
              <SettingTitle :label="{ en: 'Select product' }" variant="primary" />
            </div>
            <div class="flex h-full w-fit items-center justify-center gap-4">
              <g-tooltip placement="top" :is-teleport="true">
                <GButton
                  :loading="isLoading"
                  size="semi-medium"
                  type="ghost"
                  only-icon="polaris-refresh"
                  :disable="isLoading"
                  @click.stop="emit('refresh')" />
                <template #content>Sync product</template>
              </g-tooltip>
              <GButton only-icon="polaris-x" type="ghost" size="semi-medium" @click="close()" />
            </div>
          </div>
          <perfect-scrollbar class="max-h-[calc(100vh-200px)]">
            <div class="flex flex-col gap-8 py-16">
              <div v-if="pageType === ThemePageType.GP_PRODUCT" class="px-16">
                <OptionValue
                  id="mode"
                  :value="productStatusByPageType"
                  :options="[
                    { value: DYNAMIC_PRODUCT_STATUS, label: 'Assigned' },
                    { value: STATIC_PRODUCT_STATUS, label: 'Manually Pick' },
                  ]"
                  @control-change="handleSelectProductMode" />
              </div>
              <SearchProductComponent
                v-if="isShowSearch"
                ref="searchReference"
                :product-show-mode="productStatusByPageType"
                :page-type="pageType"
                @on-search="onSearch" />
              <SingleProductPicker
                v-if="productStatusByPageType === STATIC_PRODUCT_STATUS"
                :value="value"
                :list="list"
                :page-type="pageType"
                :product-show-mode="productStatusByPageType"
                :is-load-more="isFetchingNextPageProduct"
                :search-keyword="searchKeyword"
                :is-not-have-product-in-store="isNoProductInStore"
                :navigate-to-product-shopify="navigateToProductShopify"
                @show-more="emit('showMore')"
                @select-product-mode="handleSelectProductMode"
                @set-value-selected="setValueSelected"></SingleProductPicker>
              <AssignedProductList
                v-if="productStatusByPageType === DYNAMIC_PRODUCT_STATUS"
                :value="value"
                :list="list"
                :page-type="pageType"
                :product-show-mode="productStatusByPageType"
                :is-load-more="isFetchingNextPageProduct"
                :search-keyword="searchKeyword"
                :is-not-have-assigned="isNoAssignedProduct"
                :is-not-have-product-in-store="isNoProductInStore"
                :navigate-to-product-shopify="navigateToProductShopify"
                @open-assign-modal="handleOpenAssignModal"
                @show-more-assigned="emit('showMoreAssigned')"
                @select-product-mode="handleSelectProductMode"
                @set-value-selected="setValueSelected"></AssignedProductList>
            </div>
            <div v-if="isShowBtnManageAssigned" class="border-dark-300 border-t px-16 py-14">
              <GButton
                v-if="pageType === ThemePageType.GP_PRODUCT"
                type="secondary"
                size="medium"
                class="text-12 font-regular bg-dark-300 relative w-full"
                @click.stop="handleOpenAssignModal">
                Manage assigned products
              </GButton>
            </div>
          </perfect-scrollbar>
        </div>
      </template>
    </GPopover>
  </div>
</template>
