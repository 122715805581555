<script setup lang="ts">
import type { GridSettingInput, RowLayoutProps } from '@gem/element-setting-ui';
import { ColumnWithSlider, PresetSettingLayout, SettingLayout } from '@gem/element-setting-ui';
import { useRowLayoutSettingsUpdate } from './hooks/useRowLayoutSettingsUpdate';
import { checkSortedArray, type ScreenType } from '@gem/common';

import { computed } from 'vue';
import type { SvgName } from '@gem/icons';
import HeroBannerLayoutRecommend from './components/HeroBannerLayoutRecommend.vue';
import { getOrderItem } from '@gem/control/src/control/hero-banner-layout/helper';

const props = withDefaults(defineProps<RowLayoutProps>(), {
  maxCol: 6,
  currentScreen: 'desktop',
  isSupportResponsiveOrder: true,
});

const emit = defineEmits<{
  (
    e: 'control-change',
    id: RowLayoutProps['id'],
    value: RowLayoutProps['value'] | undefined,
    screenId?: ScreenType,
  ): void;
  (
    e: 'control-on-change',
    id: RowLayoutProps['id'],
    value: RowLayoutProps['value'] | undefined,
    screenId?: ScreenType,
  ): void;
  (
    e: 'control-change-children-v2',
    contentSide: 'left' | 'right',
    options?: {
      noRecordHistory: boolean;
    },
  ): void;
  (e: 'control-change-layout', input: GridSettingInput[]): void;
  (e: 'remove-item', uid: string): void;
  (e: 'copy-item', uid: string): void;
  (e: 'add-item'): void;
}>();

const { handleChangeLayout, handleUpdateCols, isShowColumnWithSlider } = useRowLayoutSettingsUpdate(props, emit);

const presetContent = computed((): { icon: SvgName; content: string } => {
  const cols = props.value?.cols?.length;
  if (cols === 1)
    return {
      icon: 'gp-hero-banner-center-active',
      content: 'Center',
    };
  switch (props.value?.cols?.length === 2 && swapped.value) {
    case false:
      return {
        icon: 'gp-hero-banner-left-active',
        content: 'Middle left',
      };
    case true:
      return {
        icon: 'gp-hero-banner-right-active',
        content: 'Middle right',
      };
  }
  return {
    icon: 'gp-hero-banner-center-active',
    content: 'Center',
  };
});

const swapped = computed(
  () =>
    !checkSortedArray(
      props.childrens?.map((item) => getOrderItem(item.styles?.order || {}, props.currentScreen)) || [],
    ),
);
const swapOrder = (contentSide: 'left' | 'right') => {
  if (props.value?.cols && props.value.cols.length < 2) {
    emit('control-change-children-v2', contentSide, { noRecordHistory: true });
    return;
  }
  emit('control-change-children-v2', contentSide);
};
</script>

<template>
  <PresetSettingLayout
    :label="{ en: 'Layout' }"
    :show-label="false"
    :icon="presetContent.icon"
    :content-display="presetContent.content">
    <div class="flex flex-col gap-16">
      <SettingLayout layout="vertical" label-variant="secondary" :label="{ en: 'Desktop & Tablet' }">
        <template #control>
          <HeroBannerLayoutRecommend
            :value="value"
            :swapped="swapped"
            @swap-order="swapOrder"
            @change-layout="(value) => handleChangeLayout(value, currentScreen)" />
        </template>
      </SettingLayout>
      <SettingLayout
        v-if="isShowColumnWithSlider"
        layout="vertical"
        label-variant="secondary"
        :label="{ en: 'Column width' }">
        <template #control>
          <ColumnWithSlider
            :disabled="value?.display !== 'fill'"
            :cols="value?.cols"
            @control-change="(value) => handleUpdateCols('change', value, currentScreen)"
            @control-on-change="(value) => handleUpdateCols('onChange', value, currentScreen)" />
        </template>
      </SettingLayout>
    </div>
  </PresetSettingLayout>
</template>
