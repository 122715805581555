<script lang="ts" setup>
import { useGetDataProductSelected } from '../hooks/useGetDataProductSelected';
import type { ProductSelectedProp } from '../type';

const props = defineProps<ProductSelectedProp>();

const {
  titleProduct,
  productsLengthCollection,
  collectionTitle,
  titleRelated,
  lineClampClass,
  isShowProductExcludeLength,
  productsExcludeLength,
  classContainerProductSelected,
} = useGetDataProductSelected(props);
</script>
<template>
  <div v-if="productSrcType === 'PickProduct'" :class="classContainerProductSelected">
    <img
      v-if="list.length"
      class="rounded-medium aspect-square h-40 w-40 shrink-0 object-cover"
      src="../../images/multi-products.png"
      alt="product feature img" />
    <img
      v-else
      class="rounded-medium aspect-square h-40 w-40 shrink-0 object-cover"
      src="../../images/no-product.png"
      alt="product feature img" />
    <p class="text-light-200 font-regular text-12 line-clamp-1 leading-[14px]">
      {{ titleProduct }}
    </p>
  </div>
  <div v-if="productSrcType === 'Collection'" :class="classContainerProductSelected">
    <img
      v-if="collectionDisplay?.image"
      class="rounded-medium aspect-square h-40 w-40 shrink-0 object-cover"
      :src="collectionDisplay?.image"
      alt="collection feature img" />
    <img
      v-else
      class="rounded-medium aspect-square h-40 w-40 shrink-0 object-cover"
      src="../../images/collection-thumbnail.png"
      alt="collection feature img" />
    <div class="flex flex-col justify-center">
      <p class="text-light-200 font-regular text-12 line-clamp-1 leading-[14px]">
        {{ collectionTitle }}
      </p>
      <p v-if="collectionDisplay?.id" class="text-12 font-regular text-text-dark-300 leading-5">
        {{ productsLengthCollection }}
      </p>
    </div>
  </div>
  <div v-if="productSrcType === 'RelatedProduct'" :class="classContainerProductSelected">
    <img
      class="rounded-medium aspect-square h-40 w-40 shrink-0 object-cover"
      src="../../images/collection-thumbnail.png"
      alt="related feature img" />
    <div class="flex flex-col justify-center">
      <p class="text-light-200 font-regular text-12 leading-[14px]" :class="lineClampClass">
        {{ titleRelated }}
      </p>
      <p v-if="isShowProductExcludeLength" class="text-12 font-regular text-text-dark-300 leading-5">
        Exclude {{ productsExcludeLength }} products
      </p>
    </div>
  </div>
</template>
