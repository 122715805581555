import { computed } from 'vue';
import { DYNAMIC_PRODUCT_STATUS, STATIC_PRODUCT_STATUS } from '../constant';
import type { ProductControlProps } from '../type';

export const useSetValueSelected = (props: ProductControlProps) => {
  const isThemeSectionEditor = computed(() => {
    return props.pageType == 'THEME_SECTION';
  });

  const productStatusByPageType = computed(() => {
    if (props.pageType !== 'GP_PRODUCT' && !isThemeSectionEditor.value) return STATIC_PRODUCT_STATUS;
    return props.value?.productStatus;
  });

  const isDynamicProductInThemeSection = computed(() => {
    return isThemeSectionEditor.value && productStatusByPageType.value == DYNAMIC_PRODUCT_STATUS;
  });

  const valueDefaults = computed(() => {
    return (
      props.value ?? {
        productId: 'latest',
        initialVariantId: 'latest',
        initialVariantBaseId: null,
        productHandle: 'latest',
        productStatus: STATIC_PRODUCT_STATUS,
      }
    );
  });

  const isSalePage = computed(() => {
    return props.pageType == 'GP_FUNNEL_PAGE';
  });

  const convertProductDisplay = computed(() => {
    if (productStatusByPageType.value !== DYNAMIC_PRODUCT_STATUS) {
      return props.productDisplay;
    }
    if (
      !props.pickedDynamicProduct ||
      !props.pickedDynamicProduct.productId ||
      props.pickedDynamicProduct.productId === 'latest'
    ) {
      return {
        title: 'No product assigned',
      };
    }
    return {
      id: props.pickedDynamicProduct.productId,
      title: props.pickedDynamicProduct.productTitle,
      image: props.pickedDynamicProduct.productImage,
    };
  });

  return {
    isDynamicProductInThemeSection,
    isThemeSectionEditor,
    valueDefaults,
    isSalePage,
    convertProductDisplay,
    productStatusByPageType,
  };
};
