import { computed } from 'vue';
import type { ProductSelectedProp } from '../type';

export const useGetDataProductSelected = (props: ProductSelectedProp) => {
  const productSrcType = computed(() => props.productSrcType);

  // Pick products
  const titleProduct = computed(() => (props.list.length ? `${props.list.length} products` : 'No product'));

  // Pick collection
  const productsLengthCollection = computed(() =>
    (props.collectionDisplay?.products?.length ?? 0) < 2
      ? `${props.collectionDisplay?.products?.length ?? 0} product`
      : `${props.collectionDisplay?.products?.length} products`,
  );
  const collectionTitle = computed(() =>
    props.collectionDisplay?.id ? props.collectionDisplay?.title : 'No collection',
  );

  // Related products
  const relatedTag = computed(() => props.valueSetting?.relatedTag);
  const relatedCollectionExclude = computed(() => props.valueSetting?.relatedCollectionExclude);
  const relatedTagsExclude = computed(() => props.valueSetting?.relatedTagsExclude);
  const titleRelated = computed(() => {
    const relatedTag = props.valueSetting?.relatedTag;
    if (relatedTag?.includes('collection')) return 'Same collection with assigned product';
    if (relatedTag?.includes('tags')) return 'Same tag with assigned product';
    return 'Same vendor with assigned product';
  });
  const isShowProductExcludeLength = computed(
    () => relatedTag.value?.includes('collection') || relatedTag.value?.includes('tags'),
  );

  const classContainerProductSelected = computed(() => ({
    'bg-dark-400 group relative flex h-[56px] cursor-pointer items-center gap-[11px] overflow-hidden rounded-xl p-8':
      true,
    'hover:bg-dark-250': !props.isSalePage,
    '!bg-dark-250': props.isLoading,
    'border-primary-300 border': props.productListVisibility,
  }));
  const productsExcludeLength = computed(() => {
    if (productSrcType.value !== 'RelatedProduct') return 0;
    if (relatedTag.value?.includes('collection')) {
      return (relatedCollectionExclude.value?.split(',') ?? []).length;
    }
    if (relatedTag.value?.includes('tags')) {
      return (relatedTagsExclude.value?.split(',') ?? []).length;
    }
    return 0;
  });

  const lineClampClass = computed(() => (relatedTag.value?.includes('vendor') ? 'line-clamp-2' : 'line-clamp-1'));
  return {
    titleProduct,
    productsLengthCollection,
    collectionTitle,
    titleRelated,
    lineClampClass,
    isShowProductExcludeLength,
    productsExcludeLength,
    classContainerProductSelected,
  };
};
