<script setup lang="ts">
import type { Maybe } from '@gem/common';
import { GButton } from '@gem/uikit-v2';
import { ref } from 'vue';

type PropTypes = {
  productDisplay: {
    title: string;
    image?: Maybe<string>;
    id: Maybe<string>;
  };
  isLoading?: boolean;
};
defineProps<PropTypes>();

const isDisabledTooltipParent = ref<boolean>(false);

const emit = defineEmits<{
  (e: 'goToProduct'): void;
}>();

const handleClick = () => {
  emit('goToProduct');
};
</script>

<template>
  <div class="flex flex-col gap-8" @click="handleClick">
    <g-tooltip placement="top" :is-teleport="true" :disabled="isDisabledTooltipParent">
      <div
        class="group-hover bg-dark-400 hover:bg-dark-300 group relative flex h-[56px] w-full cursor-pointer items-center gap-8 overflow-hidden rounded-xl p-8">
        <img
          v-if="productDisplay?.image"
          class="rounded-medium aspect-square h-40 w-40 shrink-0 object-cover"
          :src="productDisplay?.image"
          alt="product feature img" />
        <img
          v-else
          class="rounded-medium aspect-square h-40 w-40 shrink-0 object-cover"
          src="../../assets/no-product.png"
          alt="product feature img" />
        <p class="text-light-450 font-regular text-12 line-clamp-2 self-center leading-[14px]">
          {{ productDisplay?.title }}
        </p>
        <div
          class="z-5 absolute right-0 hidden h-full items-center justify-end gap-8 pl-[24px] pr-8 group-hover:flex"
          :style="{
            background: 'linear-gradient(270deg, #333333 66.5%, rgba(51, 51, 51, 0.8) 85.5%, rgba(51, 51, 51, 0) 100%)',
          }">
          <GButton class="btn-chevron-icon" type="ghost" size="small" only-icon="polaris-chevron-right" />
        </div>
      </div>
      <template #content>Manage in Product element</template>
    </g-tooltip>
  </div>
</template>
